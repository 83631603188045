import Index from '../pages/index/index';
import Privacy from '../pages/privacy/index';
import NotFound from '../pages/notFound';
import NotAllowed from '../pages/notAllowed';
import UserAgreement from '../pages/userAgreement'

const routes = [{
    exact: true,
    path: '/',
    redirect: '/index'
  },
  {
    exact: true,
    path: '/index',
    component: Index
  },
  {
    path: '/privacy',
    component: Privacy
  },
  {
    path: '/userAgreement',
    component: UserAgreement
  },
  {
    path: '403',
    component: NotAllowed
  },
  {
    path: '*',
    component: NotFound
  }
];

export default routes;