import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './index.css';

import logoImg from '../../images/logo-img.png';
import xcxImg from '../../images/xiaochengxu.jpg';
import img1 from '../../images/1.png';
import img2 from '../../images/2.png';
import img3 from '../../images/3.png';
import img4 from '../../images/4.png';
class index extends Component {
  constructor(props) {
    super();
    this.state = {
      changColor: false,
      /* logo会动态修改 */
      logoImg: logoImg,
    };
  }

  componentDidMount() {
    /* 绑定滚动监听事件 */
    // window.addEventListener('scroll', this.handleScroll);
  }

  /* 锚点跳转 */
  scrollToAnchor(id) {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
    });
  }

  /* 滚动的事件处理 */
  // handleScroll = (event) => {
  //   //滚动条高度
  //   let ctx = this;
  //   let scrollTop = document.documentElement.scrollTop; //滚动条滚动高度
  //   console.log(scrollTop);
  //   if (scrollTop > 80) {
  //     ctx.setState({ changColor: true });
  //     ctx.setState({ logoImg: logoImgOrange });
  //   } else {
  //     ctx.setState({ changColor: false });
  //     ctx.setState({ logoImg: logoImg });
  //   }
  // };

  render() {
    return (
      <div>
        {/* 头部导航 */}
        <div id="w-head" className={this.state.changColor ? 'w-head' : ''}>
          <div
            className={
              this.state.changColor ? 'wzg-head wzg-head-scroll' : 'wzg-head'
            }
          >
            <Row justify="start" align="middle" className="h100">
              <Col span={4}>
                <a href="/index">
                  <img
                    src={this.state.logoImg}
                    alt="logo"
                    className="logo-img"
                  />
                </a>
              </Col>
              <Col
                span={20}
                className={
                  this.state.changColor
                    ? 'w-head-right w-head-right-scroll'
                    : 'w-head-right'
                }
              >
                <a href="#!" onClick={() => this.scrollToAnchor('w-head-nav')}>
                  首页
                </a>
                <a
                  href="#!"
                  onClick={() => this.scrollToAnchor('introduction')}
                >
                  配件采购
                </a>
                <a
                  href="#!"
                  onClick={() => this.scrollToAnchor('broken-screensaver')}
                >
                  碎屏保
                </a>
                <a
                  href="#!"
                  onClick={() => this.scrollToAnchor('purchasing-by-rent')}
                >
                  以租代购
                </a>
                {/* <a href="#!" onClick={() => this.scrollToAnchor('contactUS')}>
                  关注手机妈妈
                </a> */}
              </Col>
            </Row>
          </div>
        </div>

        <div id="w-head-nav-block"></div>
        {/* 首页 */}
        <div id="w-head-nav">
          <div className="phone-dehui">
            <img src={img1} alt="平台介绍" className="img1" />
          </div>
        </div>

        {/* 配件精选 */}
        <div id="introduction">
          <img src={img2} alt="配件" className="img2" />
        </div>

        {/* 碎屏保 */}
        <div id="broken-screensaver">
          <img src={img3} alt="碎屏保" className="broken-screensaver" />
        </div>

        {/* 以租代购 */}
        <div id="purchasing-by-rent">
          <img src={img4} alt="以租代购" className="purchasing-by-rent" />
        </div>
        {/* 关注手机妈妈 */}
        <div id="contactUS">
          <div className="contactUS">
            <p>关注手机妈妈</p>
            <div className="QRCode clearfix">
              <div className="QR mr80">
                <div className="QRImg">
                  <img src={xcxImg} alt="小程序" />
                </div>
                <div className="name">小程序</div>
              </div>
              {/* <div className="QR">
                <div className="QRImg">
                  <img src={appImg} alt="APP" />
                </div>
                <div className="name">APP</div>
              </div> */}
            </div>
            <div className="copyright">
              Copyright©2021 义乌海烨电子有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19029513号-5</a> 
            </div>
            {/* <div className="record">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33070202100339"
              >
                <img src={guohuiImg} alt="国徽" />
                <span className="tb-footer-mod">
                  浙公网安备 33070202100339号
                </span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default index;
